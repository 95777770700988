import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFillTrashFill } from 'react-icons/bs';
import { transactions } from "../../config/api-routes";
import { numberToWords } from 'number-to-words';
import { FaFilePdf } from "react-icons/fa";

const header = ['Date', 'Particulars', 'Paid/Recieved', 'Amount', 'Account', 'Instrument'];

export default function TransactionView() {
    const auth = useSelector(state => state.auth);
    const [transaction, setTransaction] = useState({
        deal: {
            title: '',
            type: '',
        },
        date: '',
        particulars: '',
        coa: {
            title: '',
        },
        isCredit: false,
        amount: 0,
    });
    const [images, setImages] = useState([]);    
    const [selectedImage, setSelectedImage] = useState(null);
    const param = useParams();
    const user = useSelector(state => state.auth.user.name);

    const fetchTransaction = useCallback(async () => {
        try {
            const { data } = await axios.get(`${transactions}/${param.id}`);
            setTransaction(data);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        fetchTransaction();
    }, [fetchTransaction])

    const handleChange = (event) => {
        setImages(event.target.files);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const image of images) {
            formData.append('images', image);
        }
        try {
            const response = await axios.put(`${transactions}/${param.id}/images`, formData);
            if (response) {
                swal('Good Job!', response.data.success, 'success').then(fetchTransaction());
            }

        } catch (error) {
            swal('Failed', error.response.data, 'error');
        }
    }

    const handleImageClick = (src) => {
        setSelectedImage(src);
    };

    const handleDeleteImage = async (imageToDelete) => {
        try {
            const response = await axios.delete(`${transactions}/${param.id}/images/${encodeURIComponent(imageToDelete)}`);
            if (response) {
                swal('Success!', response.data.success, 'success');
                fetchTransaction();
            }
        } catch (error) {
            swal('Failed', error.response.data, 'error');
        }
    };

    const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const footerMargin = 50;
        const doc = new jsPDF(orientation, unit, size);
        const title = transaction.deal.title;
        const dealBy = auth.user.name;
        let posY = 155;        
        //top portion
        doc.setFillColor("#008080")
        doc.rect(0,100,400,10,'F')
        doc.setFontSize(40);
        doc.text('INVOICE',415,110)

        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('Invoice to:    ',marginLeft,posY); 
        doc.setFont('helvetica', 'normal');

        doc.setFontSize(15);                               
        doc.text('Deal:',marginLeft,posY+=40);        
        doc.text('Invoice No.:',marginLeft,posY+=20);
        doc.text('Date:',marginLeft+340,posY);

        doc.setTextColor(64, 64, 64);
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text(transaction.deal.contact.name,marginLeft+100,155);
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(15);
        doc.text(title,marginLeft+100,195);                
        doc.text('_______________',marginLeft+100,215);
        doc.text(moment(transaction.date).format('MMM-DD-YYYY'),marginLeft+400,215);
        doc.setTextColor(0, 0, 0);

        const tableData = [
            ['No.', 'Particulars', 'Paid/Received', 'Instrument', 'Amount'],
            [1, transaction.particulars, (((transaction.deal.type === 'purchase' && transaction.amount >= 0) || (transaction.deal.type === 'sell' && transaction.amount < 0)) ? 'Paid': 'Recieved'), transaction.instrument, Math.abs(transaction.amount)],
          ];

        const tableStyles = {
            fillColor: '#008080', 
            fontStyle: 'bold',
            fontSize: 13
        };

        doc.autoTable({
            head: [tableData[0]],
            body: tableData.slice(1),
            startY: 250, 
            startX: marginLeft,
            styles: tableStyles,
          });

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(13);
        doc.text('Total: ',marginLeft+10,340)
        doc.text('Rs. '+ Math.abs(transaction.amount), doc.internal.pageSize.getWidth()-55 - (doc.getStringUnitWidth('Rs. '+ Math.abs(transaction.amount)) * doc.internal.getFontSize() / doc.internal.scaleFactor),340);
        doc.text('[Rs. ' + numberToWords.toWords(Math.abs(transaction.amount)).replace(/\b\w/g, char => char.toUpperCase()) + ' Only]', doc.internal.pageSize.getWidth()-80 - (doc.getStringUnitWidth('Rs. ' + numberToWords.toWords(transaction.amount) + ' only') * doc.internal.getFontSize() / doc.internal.scaleFactor), 360);
        doc.setFont('helvetica', 'normal');               

        //image portion        
        let startY=390;
        const imageElements = document.getElementsByClassName("img-fluid");        
        if(selectedImage){
            doc.text('Attachments:',150,370);
            doc.addImage(selectedImage,'JPEG',(doc.internal.pageSize.width-300)/2,startY, 300, 200)
        }

       //footer portion    
        const y=doc.internal.pageSize.height - footerMargin;

        doc.setFontSize(13);
        doc.line(marginLeft-7,y, marginLeft+dealBy.length*8,y,'S');
        doc.text('Accountant', marginLeft, y + 15);

        doc.line(doc.internal.pageSize.width/2-45,y,doc.internal.pageSize.width/2+title.length*8,y,'S');
        doc.text(dealBy, doc.internal.pageSize.width/2-35, y + 15);        
        
        doc.line(doc.internal.pageSize.getWidth()-135,y,doc.internal.pageSize.getWidth()-35,y,'S');
        doc.text("Paid to", doc.internal.pageSize.getWidth()-120, y + 15);
        
        doc.save(`${Date.now()}-${transaction.deal.title}.pdf`);
    };

    return (
        <div className='users container w-75 m-auto mt-5'>
            
            <div className="d-flex justify-content-between">
            <h2 className="lead">Transaction - {transaction.deal.title}</h2>
                <span className="c-cursor" onClick={exportPDF}>
                    <FaFilePdf className="pdf-icon" />
                </span>                
            </div>
            <div className='table-responsive'>
                <table className="table" id="transaction-table">
                    <thead className='table-primary-head'>
                        <tr>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(transaction.date).format('MMM-DD-YYYY')}</td>
                            <td>{transaction.particulars}</td>
                            <td>{((transaction.deal.type === 'purchase' && transaction.amount >= 0) || (transaction.deal.type === 'sell' && transaction.amount < 0)) ? 'Paid': 'Recieved'}</td>
                            <td>{Math.abs(transaction.amount).toLocaleString("en-US")}</td>
                            <td>{transaction.coa.title}</td>
                            <td>{transaction.instrument}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='row mt-5'>
                <div className="col-2">Upload images: </div>
                <div className="col-4">
                    <input
                        className="form-control"
                        type="file" id="formFile"
                        name="image"
                        onChange={handleChange} multiple
                    />
                </div>

                <div className='col-2'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
            <div className="image-grid mt-5">
                {transaction.images && transaction.images.length > 0 && transaction.images.map((each, idx) => (
                    <div key={idx} className="image-container text-center">
                        <img
                            src={each}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="img-fluid shadow rounded"
                            alt={idx}
                            onClick={() => handleImageClick(each)}
                        />
                        <div className="btn btn-outline-danger m-1 col-5 p-1"  >
                            <BsFillTrashFill onClick={() => handleDeleteImage(each)} size={15} className="text-danger"/> Delete
                        </div>
                    </div>
                    
                ))}
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-body">
                        {selectedImage && <img src={selectedImage} className="img-fluid" alt="selected" />}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col">
                    <div>______________________</div>
                    <p className="lead">{user}</p>
                </div>

                <div className="col">
                    <div>______________________</div>
                </div>

                <div className="col">
                    <div>______________________</div>
                    <p className="lead">Accountant</p>
                </div>
            </div>
        </div>
    );
}