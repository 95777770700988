import { Link, useRouteMatch } from 'react-router-dom';
import Navbar from './Nav';

const appRoutes = [
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Contact', url: '/contact' },
    { name: 'Chart Of Account', url: '/coa' },
    { name: 'General Ledger', url: '/general-ledger' },
    { name: 'Transactions', url: '/transaction'},
    { name: 'Summary', url:'/summary'},
    { name: 'Users', url: '/user' },
];

function Layout({ children }) {

    const routeMatch = useRouteMatch();

    const routes =
        appRoutes.map((route, idx) => (
            <li key={idx} className='mb-2'>
                <Link to={route.url} className={'nav-link' + (route.url === routeMatch.url ? ' active' : ' text-white')}>
                    {route.name}
                </Link>
            </li>
        ));

    return (
        <main>
            <div className="sidebar d-flex flex-column flex-shrink-0 p-3 bg-light">
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-4">Accounting software</span>
                </a>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto">
                    {routes}
                </ul>
                {/* <hr />
                <div className="dropdown">
                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                        <span className="username">User</span>
                    </a>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                        <li><a className="dropdown-item" href="#">Sign out</a></li>
                    </ul>
                </div> */}
            </div>
            <div className='container-fluid overflow-y-auto'>
                <Navbar />
                {children}
            </div>
        </main>
    );
}

export default Layout;