import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import { register,users } from '../../config/api-routes';

function CreateUser() {
    const [data, setData] = useState({
        name: "",
        username: "",
        email: "",
        contact: "",
        securityQ: "",
        securityA: "",
        password: "",
        password2: ""
    });
    const [isEdit, setIsEdit] = useState(false);
    const param = useParams();
    const history = useHistory();

    const fetchUser = useCallback(async () => {
        try {
            const { data } = await axios.get(`${users}/${param.id}`);
            setData(data);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        if (param.id) {
            setIsEdit(true);
            fetchUser();
        }
    }, [fetchUser, param.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isEdit){
            try {
                const response = await axios.put(`${users}/${param.id}`, data);             
                swal('Good Job!',response.data.success,'success');
            } catch (error) {
                swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
            }
        } else {
            try {
                const response = await axios.post(register,data);
                swal('Good job', response.data.success, 'success');
            } catch (error) {
                swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
            }
        }
    }
    return (
        <div className='container w-50 bg-white p-3 rounded m-auto my-5'>
            <h2>{isEdit ? 'Edit User' : 'Create a new user'}</h2>
            <form>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='name'
                                value={data.name}
                                onChange={e => setData({ ...data, name: e.target.value })}
                                required />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='username'
                                value={data.username}
                                onChange={e => setData({ ...data, username: e.target.value })}
                                required />
                            <label htmlFor="username">Username</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type="email" className="form-control" id="email" placeholder="name@example.com" value={data.email}
                                onChange={e => setData({ ...data, email: e.target.value })}
                                required />
                            <label htmlFor="email">Email address</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='contact'
                                value={data.contact}
                                onChange={e => setData({ ...data, contact: e.target.value })}
                                required />
                            <label htmlFor="contact">Contact</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='security question'
                                value={data.securityQ}
                                onChange={e => setData({ ...data, securityQ: e.target.value })}
                                onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                                required />
                            <label htmlFor="security question">Security Question</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='security answers'
                                value={data.securityA}
                                onChange={e => setData({ ...data, securityA: e.target.value })}
                                onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                                required />
                            <label htmlFor="security answers">Security Answer</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='password'
                                className='form-control'
                                id='Password'
                                value={data.password}
                                onChange={e => setData({ ...data, password: e.target.value })}
                                required />
                            <label htmlFor="Password">Password</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='password'
                                className='form-control'
                                id='password2'
                                value={data.password2}
                                onChange={e => setData({ ...data, password2: e.target.value })}
                                required />
                            <label htmlFor="password2">Confirm Password</label>
                        </div>
                    </div>
                </div>
            </form>
            <div className='row mt-5 justify-content-center'>
                <div className='col-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default CreateUser;