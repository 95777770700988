import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { deals } from "../../config/api-routes";


export default function CreateDeal() {
    const [isEdit, setIsEdit] = useState(false)
    const [data, setData] = useState({
        title: "",
        type: "",
        value: "",
        currency: "PKR",
        desc: "",
    });
    const param = useParams();
    const history = useHistory();

    // in case of editing a deal
    const fetchDeal = useCallback(async () => {
        try {
            const { data } = await axios.get(`${deals}/${param.id}`);
            setData(data);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        if (param.id) {
            setIsEdit(true);
            fetchDeal();
        }
    }, [fetchDeal, param.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEdit) {
                const response = await axios.put(`${deals}/${param.id}`, data);
                if (response.data) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.goBack());
                }
            } else {
                const response = await axios.post(deals, { ...data, contact: param.contactId });
                if (response) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.push(`/transaction/new/${response.data.payload._id}`));
                }
            }

        } catch (error) {
            swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
        }
    };

    return (

        <div className='container w-50 bg-white p-3 rounded m-auto my-5'>
            <h2>{isEdit ? 'Edit Deal' : 'Create Deal'}</h2>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='title'
                            value={data.title}
                            onChange={e => setData({ ...data, title: e.target.value })}
                            required 
                        />
                        <label htmlFor="title">Title</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <select className="form-select"
                            id="type"
                            value={data.type}
                            onChange={e => setData({ ...data, type: e.target.value })}
                            required>
                            <option defaultValue="select type">Select type</option>
                            <option value="purchase">Purchasing</option>
                            <option value="sell">Selling</option>
                            <option value="LOAN">LOAN</option>
                        </select>
                        <label htmlFor="type">Deal type</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='value'
                            value={data.value}
                            onChange={e => setData({ ...data, value: e.target.value })}
                            required 
                        />
                        <label htmlFor="value">Value of deal</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <select className="form-select"
                            id="currency"
                            value={data.currency}
                            onChange={e => setData({ ...data, currency: e.target.value })}
                            required>
                            <option>Select currency</option>
                            <option value="PKR">PKR</option>
                            <option value="USD">USD</option>
                            <option value="USD">GBP</option>
                            <option value="USD">YAN</option>
                            <option value="USD">SAR</option>
                            <option value="USD">AED</option>
                        </select>
                        <label htmlFor="currency">Currency</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <textarea
                            className='form-control'
                            id='desc'
                            value={data.desc}
                            onChange={e => setData({ ...data, desc: e.target.value })}
                            required ></textarea>
                        <label htmlFor="desc">Details of deal</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className='col-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )


}