import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiMoreVertical, FiPlus } from 'react-icons/fi';
import { RiContactsFill } from 'react-icons/ri';
import axios from "axios";
import swal from 'sweetalert';
import Pagination from "../pagination";
import { users } from "../../config/api-routes";

const header = ['Name', 'Email', 'Username', 'Privelege'];

export default function UserList() {
    const auth = useSelector(state => state.auth);
    const isAdmin = (auth.user.privilege==='admin');
    const [request, setRequest] = useState(false);
    // console.log(" request:", request);

    const [data, setData] = useState([]);
    // console.log(data);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [text, setText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const setAsAdmin = async (userId) => {
        try {
          const response = await axios.put(`${users}/${userId}`, { privilege: 'admin' });
          if (response.status === 200) {
            setData((prevData) =>
              prevData.map((user) =>
                user._id === userId ? { ...user, privilege: 'admin' } : user
              )
            );
            swal('Success!', 'User is now an admin.', 'success').then(fetchUser());
            setRequest(false);
          }
        } catch (error) {
            swal('Failed!', 'Failed to make the user an admin.', 'error');
        }
      };

      const enableUser = async (userId) => {
        try {
          const response = await axios.put(`${users}/${userId}`, { isEnabled: true });
          if (response.status === 200) {
            setData((prevData) =>
              prevData.map((user) =>
                user._id === userId ? { ...user, isEnabled: true } : user
              )
            );
            swal('Success!', 'User is now enabled.', 'success');
            setRequest(!request);
          }
        } catch (error) {
            swal('Failed!', 'Failed to enable the user.', 'error');
        }
      };

    const deleteUser = async (userId) => {
        try {
            const response = await axios.delete(`${users}/${userId}`);
        } catch (error) {
            swal('Failed!', 'Failed to delete User', 'error');
        }
    } 

    const fetchUser = useCallback(async () => {
        try {
            const { data } = await axios.get(`${users}?page=${pagination.page}&search=${text}&isEnabled=${!request}`);
            setData(data.users);
            setPagination({
                total: data.total,
                page: data.page,
            });
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [pagination.page, text, request]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser,request]);

    //for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);


    return (
        <div className='users container w-75 m-auto mt-5'>
            <div className='row mb-3'>
                <div className='col-3'>
                    <h2>Users</h2>
                </div>
                { isAdmin ? 
                    <div className='col-4 text-end'>
                        <div className="btn btn-primary" onClick={()=>setRequest(!request)}>
                            <RiContactsFill/>  <span className="m-2">Requests</span>
                        </div>
                    </div> 
                : ''}
                <div className='col-4 text-end'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search by name or email'
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                { isAdmin ? <div className='col-1 text-end'>
                    <Link
                        to="/user/new"
                        className='btn btn-primary btn-wh'
                    >
                        <FiPlus />
                    </Link>
                </div> : ''}
            </div>

            <div className='table-responsive'>
                <table className="table">
                    <thead className='table-primary-head'>
                        <tr>
                            <td>#</td>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                            {isAdmin && <td></td>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((each, idx) => (
                            <tr key={each._id}>
                                <td>{idx + 1}</td>
                                <td>{each.name}</td>
                                <td>{each.email}</td>
                                <td>{each.username}</td>
                                <td>{each.privilege}</td>
                                {isAdmin && <td className="text-end">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiMoreVertical />
                                        </span>
                                        {each.isEnabled ? 
                                            <ul className="dropdown-menu">
                                                <li><Link to={'/user/' + each._id} className="dropdown-item">Edit</Link></li>                                            
                                                <li className="dropdown-item" onClick={() => setAsAdmin(each._id)}>Make Admin</li>
                                                {each.privilege==='regular' && 
                                                    <li className="dropdown-item" onClick={() => deleteUser(each._id)}>Delete User</li>
                                                }
                                            </ul> : 
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item btn" onClick={() => enableUser(each._id)}>Enable User</li>
                                            </ul>
                                        }
                                    </div>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageNumbers = {disPlayPages}
            />
        </div>
    );
}