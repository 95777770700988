import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment'
import { FiMoreVertical } from 'react-icons/fi';
import { AiFillFileExcel } from 'react-icons/ai';
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";
import Pagination from "../pagination";
import { transactions } from "../../config/api-routes";

const header = ['Contact', 'Deal','Value', 'Received', 'Paid', 'Balance'];

function Summary() {
    const [contactList,setContactsList] = useState([]);    
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const fetchContacts = useCallback (async () => {
        try {
            const { data } = await axios.get(`${transactions}/deals/contacts`);                     
            setContactsList(data.contacts);         
        } catch (error) {
            swal('Failed!', error.response, 'error');
        }
    },[])

    useEffect(() => {
        fetchContacts();        
    },[])


    const csvContent = `Property Deals\n\nContact,Deal Particulars,Amount,Total Payment,Balance\n\n${contactList
        .map((contact) =>
            contact.deals
                .map((deal, dealIndex) => {
                    const row = [
                        dealIndex === 0 ? contact.name : '',
                        deal.title,
                        deal.value,
                        deal.sum,
                        `${deal.type === 'sell' ? '(' : ''}${deal.value - deal.sum}${deal.type === 'sell' ? ')' : ''}`
                    ];
                    return row.join(',');
                })
                .join('\n') +
            `\n\n,Total,${contact.deals.reduce((total, deal) => total + deal.value, 0)},${contact.deals.reduce((total, deal) =>  total + deal.sum, 0)},${contact.deals.reduce((total, deal) =>  total + (deal.value - deal.sum), 0)},\n\n`
        )
        .join('\n')}`;


    const ExportToExcel = () => {
        const blob = new Blob([csvContent ], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `summary-${moment().format('YYYY-MM-DD')}.csv`);
        link.click();
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = contactList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(contactList.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);    
    
  return (
    <div className='users container w-75 m-auto mt-5'>
        <div className='row mb-3'>
            <div className='col-6'>
                <h2>Summary</h2>
            </div>
            <div className='col-6 text-end d-flex justify-content-end'>
                <AiFillFileExcel type="button" onClick={ExportToExcel} className="text-success" size={40}/>
                <h4 className="ps-3"> As of {moment().format('MMM-DD-YYYY')}</h4>
            </div>
        </div>
        <div className='table-responsive'>
            <table className='table border' id='transactions-table'>
                <thead className='table-primary-head'>
                    <tr>
                        <td>#</td>
                        {header.map((each, idx) => (
                            <td key={idx}>{each}</td>
                        ))}
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {currentRecords.map((contact,idx) => (
                        contact.deals.map((deal, index) => (
                            <tr key={idx+index}>
                               {index===0 && (
                                <>
                                    <td className="border" rowSpan={contact.deals.length}>{idx+1}</td>
                                    <td className= "border" rowSpan={contact.deals.length}>{contact.name}</td>
                                </>
                                )}
                                <td>{deal.title}</td>
                                <td>{deal.value}</td>
                                <td>{deal.type === 'sell' ? deal.sum : ''}</td>
                                <td>{deal.type === 'sell' ? '' : deal.sum}</td>
                                <td>
                                    {deal.type === 'sell' ? '('+(deal.value - deal.sum).toLocaleString("en-US")+')' : (deal.value - deal.sum).toLocaleString("en-US")}
                                </td>
                                <td>
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiMoreVertical />
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li><Link to={'/transaction/deals/' + deal._id} className="dropdown-item">View Transactions</Link></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>                           
                        ))                        
                    ))}
                </tbody>
            </table>
        </div>
        <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers = {disPlayPages}
        />
    </div>
  )
}

export default Summary