import { useState } from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage, pageNumbers }) => {

    return (
        <nav aria-label='Table Pagination'>
            <ul className='pagination justify-content-end'>
                <li className={`page-item ${currentPage === 1 ? 'disabled':''}`}>
                    <a className="page-link"
                        onClick={() => setCurrentPage(currentPage-1)}
                        href='#'>
                        Previous
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber}
                        className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                        <a onClick={() => setCurrentPage(pgNumber)}
                            className='page-link'
                            href='#'>
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className={`page-item ${currentPage === nPages ? 'disabled':''}`}>
                    <a className="page-link"
                        onClick={() => setCurrentPage(currentPage+1)}
                        href='#'>
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination