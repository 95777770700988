import axios from "axios";
import { Link, useParams } from "react-router-dom";
import moment from 'moment'
import { FiMoreVertical } from 'react-icons/fi';
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";
import { transactions } from "../../config/api-routes";
import jsPDF from "jspdf";
import { FaFilePdf } from "react-icons/fa";

const header = ['Date', 'Particulars', 'Bank', 'Received', 'Paid', 'LOAN', 'Deal', 'Contact']; 

function Transactions() {
    const param = useParams();
    const [transactionList,setTransactionsList] = useState([]);
    const [selectedDealType, setSelectedDealType] = useState("");
    const [filterCoa, setFilterCoa] = useState(!!param.id);

    const fetchTransaction = useCallback (async () => {
        try {
            const { data } = await axios.get(`${transactions}?type=${selectedDealType}${filterCoa ? `&coa=${param.id}`:''}`);
            setTransactionsList(data.transactions)
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [selectedDealType])

    useEffect(() => {
        fetchTransaction();
    },[selectedDealType])

    const handleDealTypeChange = (event) => {
        setSelectedDealType(event.target.value); 
      };

      const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = 'All Transactions';

        //top portion
        doc.setFontSize(15);
        doc.text(title, marginLeft, 40);

        const tableContent = [];

        const headerRow = ['#', ...header];
        tableContent.push(headerRow);

        transactionList.forEach((each, idx) => {
            const rowData = [
                idx + 1,
                moment(each.date).format('MMM-DD-YYYY'),
                each.particulars,
                each.coa?.title ? each.coa.title : '',
                ((each.deal.type === 'sell' && each.amount >= 0) || (each.deal.type === 'purchase' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : '',
                ((each.deal.type === 'purchase' && each.amount >= 0) || (each.deal.type === 'sell' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : '',
                each.deal.type === 'LOAN' ? each.amount.toLocaleString("en-US") : '',
                each.deal.title,
                each.deal.contact.name                
            ];
            tableContent.push(rowData);
        });

        //table content
        doc.autoTable({
            //html: '#transaction-table',
            head: [tableContent[0]],
            body: tableContent.slice(1),
            startY: 80,            
        });
        
        doc.save('transactions.pdf');
    };
    
  return (
    <div className='users container w-75 m-auto mt-5'>
        <div className='row mb-3'>
            <div className='col-3'>
                <h2>Transactions</h2>
            </div>
            <div className='col-4 mb-3'>
            <div className='row align-items-center'>
                <div className='col-4'>Filter By:</div>
                <div className='col-7'>
                    <select
                        id="dealType"
                        name="dealType"
                        className="form-select"
                        onChange={handleDealTypeChange}
                        value={selectedDealType} 
                    >
                        <option value="">Type</option>
                        <option value="sell">Received</option>
                        <option value="purchase">Paid</option>
                        <option value="LOAN">Loan</option>
                    </select>
                </div>
            </div>
        </div>
        <div className='row mb-3 col-1'>
            <div className='col-12 text-end'>
            <span className="text-main c-cursor me-3" onClick={exportPDF}>
                <FaFilePdf className="pdf-icon" />
            </span>
            </div>
        </div>
        <div className='col-4 text-end'>
            <h4> As of {moment().format('MMM-DD-YYYY')}</h4>
        </div>
        </div>
        <div className='table-responsive'>
            <table className='table' id='transaction-table'>
                <thead className='table-primary-head'>
                    <tr>
                        <td>#</td>
                        {header.map((each, idx) => (
                            <td key={idx}>{each}</td>
                        ))}
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {transactionList.map((each,idx) => (
                        <tr key={each._id}>
                            <td>{idx+1}</td>
                            <td>{moment(each.date).format('MMM-DD-YYYY')}</td>
                            <td>{each.particulars}</td>
                            <td>{each.coa?.title ? each.coa.title : ''}</td>
                            <td>{((each.deal.type === 'sell' && each.amount >= 0) || (each.deal.type === 'purchase' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : ''}</td>
                            <td>{((each.deal.type === 'purchase' && each.amount >= 0) || (each.deal.type === 'sell' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : ''}</td>
                            <td>{each.deal.type === 'LOAN' ? each.amount.toLocaleString("en-US") : ''}</td>
                            <td><Link to={`/transaction/deals/${each.deal._id}`}>{each.deal.title}</Link></td>
                            <td><Link to={`/deal/view/${each.deal.contact._id}`}>{each.deal.contact.name}</Link></td>
                            <td className="text-end">
                            <div className="dropdown">
                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FiMoreVertical />
                                </span>
                                <ul className="dropdown-menu">
                                    <li><Link to={`/transaction/view/${each._id}`} className="dropdown-item">View</Link></li>
                                </ul>
                            </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Transactions