import React, { useState } from 'react';
import LogoImage from '../../assets/logo.png';
import { changePassword, forgotPassword } from '../../config/api-routes';
import {  useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

function ForgotPassword() {

    const [datainfo, setDataInfo] = useState({
        email: '',
        securityA: '',
        password1: '',
        confirmPassword: '',
    });
    const [securityQ, setSecurityQ] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const history = useHistory();

    const verifyUser = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(forgotPassword, datainfo);
            if(data.securityQ) {
                setSecurityQ(data.securityQ); 
                setIsVerified(false);
            }
            if(data.success){
                setIsVerified(true);
            }
                       
        } catch (error) {
            console.log(error);
            swal('Failed!', error.response.data, 'error');
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.put(changePassword,datainfo);
            swal('Good Job!',response.data.success,'success'); 
            history.push('/');           
        } catch (error) {
            console.log(error)
            swal('Failed!', error.response.data.password, 'error');
        }
    }

  return (
    <div className='container-fluid'>
            <form className='auth-form auth-login'>
                <div className='text-center'>
                    <img src={LogoImage} className='img-fluid' width={50} height={50} alt='Workhall Logo' />
                </div>
                <h2 className='text-center mb-3'>{isVerified? 'Set New Password' : 'Forgot Password'}</h2>
                {!isVerified &&
                    <>
                        <div className='form-floating mb-4'>
                        <input 
                            type='text' 
                            className='form-control' 
                            id='email' 
                            placeholder='name@example.com' 
                            value={datainfo.email}
                            onChange={e => setDataInfo({...datainfo, email: e.target.value})}
                            required
                        />
                        <label htmlFor='email' className='form-label'>Username</label>
                        </div>
                        {securityQ && 
                            <>
                                <div className='ms-2'>
                                    <p>Q: { securityQ }</p>
                                </div>
                                <div className='form-floating mb-4'>                    
                                    <input 
                                        type='text' 
                                        className='form-control' 
                                        id='securityA'                         
                                        value={datainfo.securityA}
                                        onChange={e => setDataInfo({...datainfo, securityA: e.target.value})}
                                        onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                                        required
                                    />
                                    <label htmlFor='securityA' className='form-label'>Security Answer</label>
                                </div>
                            </>
                        }                        
                        <div className='text-end mt-3 mb-3'>
                            <button className='btn btn-primary btn-wh w-50' onClick={verifyUser}>Next</button>
                        </div>
                    </>                
                }
                {
                    isVerified && 
                    <>
                        <div className="form-floating mb-4">
                            <input type='password'
                                className='form-control'
                                id='Password'
                                value={datainfo.password1}
                                onChange={e => setDataInfo({ ...datainfo, password1: e.target.value })}
                                required />
                            <label htmlFor="Password">Password</label>
                        </div>
                        <div className="form-floating mb-4">
                            <input type='password'
                                className='form-control'
                                id='password2'
                                value={datainfo.confirmPassword}
                                onChange={e => setDataInfo({ ...datainfo, confirmPassword: e.target.value })}
                                required />
                            <label htmlFor="password2">Confirm Password</label>
                        </div>
                        <div className='text-end mt-3 mb-3'>
                            <button className='btn btn-primary btn-wh w-50' onClick={handleSubmit}>Submit</button>
                        </div>
                    </>
                }
            </form>
        </div>
  )
}

export default ForgotPassword