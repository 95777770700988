import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import axios from "axios";
import swal from 'sweetalert';
import Pagination from "../pagination";
import { charOfAccount } from "../../config/api-routes";
import jsPDF from "jspdf";
import { FaFilePdf } from "react-icons/fa";

const header = ['Title', 'Type', 'Code', 'Initial Value', 'Current Value', 'Description'];

function CoaList() {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [type, setType] = useState('');
    const [text, setText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const fetchCoa = useCallback(async () => {
        try {
            const { data } = await axios.get(`${charOfAccount}?type=${type}&search=${text}`);
            setData(data.accounts);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [text, type]);

    useEffect(() => {
        fetchCoa();
    }, [fetchCoa]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);    

    const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3, or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = 'Chart Of Accounts';

        //top portion
        doc.setFontSize(15);
        doc.text(title, marginLeft, 40);

        // Clone the table to avoid modifying the original
        const table = document.getElementById('coa-table');
        const clonedTable = table.cloneNode(true);

        // Remove the last td from each row
        clonedTable.querySelectorAll('tr').forEach(row => {
            const cells = row.getElementsByTagName('td');
            if (cells.length > 0) {
                cells[cells.length - 1].remove();
            }
        });

        //table content
        doc.autoTable({
            html: clonedTable,
            startY: 80,
        });

        doc.save('Chart Of Accounts.pdf');
    };

    return (
        <div className='users container w-75 m-auto mt-5'>
            <div className='row mb-3'>
                <div className='col-4'>
                    <h2>Chart Of Account</h2>
                </div>
                <div className='col-3 text-end'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search by Title'
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                <div className='row mb-3 col-1'>
                    <div className='col-12 text-end'>
                    <span className="text-main c-cursor me-3" onClick={exportPDF}>
                        <FaFilePdf className="pdf-icon" />
                    </span>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='row align-items-center'>
                        <div className='col-5 text-end'>Filter By:</div>
                        <div className='col-7'>
                            <select className='form-select' onChange={e => setType(e.target.value)}>
                                <option value=''>Type</option>
                                <option value="Bank">Bank</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque in hand">Cheque in hand</option>
                                <option value="Cheque outwards">Cheque outwards</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-1 text-end'>
                    <Link
                        to="/coa/new"
                        className='btn btn-primary btn-wh'
                    >
                        <FiPlus />
                    </Link>
                </div>
            </div>

            <div className='table-responsive'>
                <table className="table" id="coa-table">
                    <thead className='table-primary-head'>
                        <tr>
                            <td>#</td>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((each, idx) => (
                            <tr key={each._id}>
                                <td>{idx + 1}</td>
                                <td><Link to={'transaction/coa/' + each._id}>{each.title}</Link></td>
                                <td>{each.type}</td>
                                <td>{each.code}</td>
                                <td>{each.initialValue}</td>
                                <td>{each.value}</td>
                                <td>{each.desc}</td>                                
                                    <td className="dropdown">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiMoreVertical />
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li><Link to={'/coa/' + each._id} className="dropdown-item">Edit</Link></li>
                                            <li><Link to={`/logs/coa/${each._id}`} className='dropdown-item'>View logs</Link></li>
                                        </ul>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageNumbers = {disPlayPages}
            />
        </div>
    );

}

export default CoaList;