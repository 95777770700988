import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import swal from 'sweetalert';
import Pagination from "../pagination";
import { contacts } from "../../config/api-routes";
import { useSelector } from "react-redux";


const header = ['Name', 'View', ''];

function ContactList() {
    const auth = useSelector(state => state.auth);
    const isAdmin= (auth.user.privilege==='admin');
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [type, setType] = useState('');
    const [text, setText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const fetchcontacts = useCallback(async () => {
        try {
            const { data } = await axios.get(`${contacts}?page=${pagination.page}&type=${type}&search=${text}`);
            setData(data.contacts);
            setPagination({
                total: data.total,
                page: data.page,
            });
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [pagination.page, text, type]);

    useEffect(() => {
        fetchcontacts();
    }, [fetchcontacts]);

    //for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);

    const handleDelete = id => () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                try {
                    const response = await axios.delete(`${contacts}/${id}`);
                    if (response) {
                        swal(response.data.success, 'success').then(fetchcontacts());
                    }
                } catch (response) {
                    swal(response.data, 'error');
                }
            } else {
                swal("This record is safe!");
            }
        });
    };

    return (
        <div className='users container w-75 m-auto mt-5'>
            <div className='row mb-3'>
                <div className='col-4'>
                    <h2>Contacts</h2>
                </div>
                <div className='col-4 text-end'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search by name or email'
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                <div className='col-3'>
                    <div className='row align-items-center'>
                        <div className='col-5 text-end'>Filter By:</div>
                        <div className='col-7'>
                            <select className='form-select' onChange={e => setType(e.target.value)}>
                                <option value=''>Type</option>
                                <option value='individual'>Individual</option>
                                <option value='company'>Company</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-1 text-end'>
                    <Link
                        to="/contact/new"
                        className='btn btn-primary btn-wh'
                    >
                        <FiPlus />
                    </Link>
                </div>
            </div>

            <div className='table-responsive'>
                <table className="table">
                    <thead className='table-primary-head'>
                        <tr>
                            <td>#</td>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((each, idx) => (
                            <tr key={each._id}>
                                <td>{idx + 1}</td>
                                <td>{each.name}</td>
                                <td><Link to={`/deal/view/${each._id}`}>View Deals</Link></td>
                                <td><div className="dropdown text-end">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiMoreVertical />
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li><Link to={`/contact/${each._id}`} className="dropdown-item">Edit</Link></li>
                                            { isAdmin ? 
                                                <li><button className="dropdown-item" onClick={handleDelete(each._id)}>Delete</button></li> : ''
                                            }
                                        </ul>
                                    </div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageNumbers = {disPlayPages}
            />
        </div>
    );
}

export default ContactList;