import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import { login } from '../../config/api-routes';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import swal from 'sweetalert';

// Login - Get User Token
export const loginUser = userData => async dispatch => {
	try {
		const { data } = await axios.post(login, userData);		
		const { token } = data;
		localStorage.setItem('jwtToken', token);
		setAuthToken(token);
		const decoded = jwt_decode(token);
		dispatch(setCurrentUser(decoded));
	} catch (error) {
		if (typeof error.response.data === 'string') {
			swal('Failed!', error.response.data, 'error');
		  } else if (typeof error.response.data === 'object') {
			const errorMessages = Object.values(error.response.data).join('\n');
			swal('Failed!', errorMessages, 'error');
		  }
		dispatch({
			type: GET_ERRORS,
			payload: error.response.data,
		});
	}
};

// Set Logged in User
export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

// Log Uer Out
export const logoutUser = () => dispath => {
	localStorage.clear();
	setAuthToken(false);
	dispath(setCurrentUser({}));

	window.location.href = '/';
};