import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { FaFilePdf } from 'react-icons/fa';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { transactions } from '../../config/api-routes';

const header = ['Date', 'Deal Title', 'Bank', 'Particulars', 'Received', 'Paid', 'Balance'];

export default function GeneralLedger() {
    const [data, setData] = useState([]);
    const [dealTotal, setDealTotal] = useState(0);

    const fetchTransaction = useCallback(async () => {
        try {
            const { data } = await axios.get(`${transactions}`);
            setData(data.transactions);
            let dealAmountTotal = 0;
            let transacationsData = [];
            // calculations for the deal
            [...new Map(data.transactions.map(v => [v.deal._id, v.deal])).values()]
                .forEach((eachDeal) => {
                    dealAmountTotal += eachDeal.value;
                });
            let balanceAmount = dealAmountTotal;
            data.transactions.forEach((each) => {
                balanceAmount = balanceAmount - each.amount;
                transacationsData.push({
                    ...each,
                    balance: balanceAmount,
                });
            });
            setData(transacationsData);
            setDealTotal(dealAmountTotal);
        } catch (error) {
            swal('Failed', error.response.data, 'error');
        }
    }, []);

    useEffect(() => {
        fetchTransaction();
    }, [fetchTransaction]);

    const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = 'General Ledger';
        const total = dealTotal.toLocaleString('en-US')
    
        //top portion
        doc.setFontSize(15);
        doc.text(title, marginLeft, 40);
        doc.text('Total: ' + total, 450, 40);
    
        const table = document.getElementById('transaction-table');
        const clonedTable = table.cloneNode(true);

        clonedTable.querySelectorAll('tr').forEach(row => {
            const cells = row.getElementsByTagName('td');
            if (cells.length > 0) {
                cells[cells.length - 1].remove();
            }
        });
    
        //table content
        doc.autoTable({
            html: clonedTable,
            startY: 80,
        });
    
        doc.save('general-ledger.pdf');
    };
    

    return (
        <div className='users container w-75 m-auto my-5'>
            <div className='row mb-3'>
                <div className='col-4'>
                    <h2>Cash Book</h2>
                </div>
                <div className='col-4 text-end'>
                    <span className="text-main c-cursor me-3" onClick={exportPDF}>
                        <FaFilePdf className="pdf-icon" />
                    </span>
                </div>
                <div className='col-4 text-end'>
                    <p className='lead'>Total: PKR {dealTotal.toLocaleString('en-US')}</p>
                </div>
            </div>
            
            <div className='table-responsive'>
                <table className='table' id='transaction-table'>
                    <thead className='table-primary-head'>
                        <tr>
                            <td>#</td>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                            <td />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((each, idx) => (
                            <tr key={each._id}>
                                <td>{idx + 1}</td>
                                <td>{moment(each.date).format('DD-MM-YYYY')}</td>
                                <td>{each.deal.title}</td>    
                                <td>{each.coa.title}</td>
                                <td>{each.particulars}</td>
                                <td>{each.deal.type === 'sell' ? each.amount.toLocaleString("en-US") : ''}</td>
                                <td>{each.deal.type !== 'sell' ? each.amount.toLocaleString("en-US") : ''}</td>
                                <td>
                                    {(+each.balance).toLocaleString("en-US")}
                                    {each.deal.type === 'sell' ? ' (to be received)' : ' (to be paid)'}
                                </td>
                                <td className='text-end'>
                                    <div className='dropdown'>
                                        <span className='dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                            <FiMoreVertical />
                                        </span>
                                        <ul className='dropdown-menu'>
                                            <li><Link to={'/transaction/view/' + each._id} className='dropdown-item'>View Transaction</Link></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}