import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { charOfAccount } from "../../config/api-routes";

function CreateCoa() {
    const [data, setData] = useState({
        title: "",
        value: "",
        type: "",
        currency: "PKR",
        desc: "",
    });
    const [isEdit, setIsEdit] = useState(false);
    const param = useParams();
    const history = useHistory();

    const fetchCoa = useCallback(async () => {
        try {
            const { data } = await axios.get(`${charOfAccount}/${param.id}`);
            setData(data);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        if (param.id) {
            setIsEdit(true);
            fetchCoa();
        }
    }, [fetchCoa, param.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEdit) {
                const response = await axios.put(`${charOfAccount}/${param.id}`, data);
                if (response) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.push(`/coa`));
                }
            } else {
                const response = await axios.post(charOfAccount, data);
                if (response) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.push(`/coa`));
                }
            }
        } catch (error) {
            swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
        }
    };

    return (
        <div className='container w-50 bg-white p-3 rounded m-auto my-5'>
            <h2>{isEdit ? 'Edit Account' : 'Create a new Account'}</h2>
            <form noValidate>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='title'
                                value={data.title}
                                onChange={e => setData({ ...data, title: e.target.value })}
                                required
                            />
                            <label htmlFor="title">Title</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <select className="form-select"
                                id="currency"
                                value={data.currency}
                                onChange={e => setData({ ...data, currency: e.target.value })}
                                required>
                                <option>Select currency</option>
                                <option value="PKR">PKR</option>
                                <option value="USD">USD</option>
                                <option value="USD">GBP</option>
                                <option value="USD">YAN</option>
                                <option value="USD">SAR</option>
                                <option value="USD">AED</option>
                            </select>
                            <label htmlFor="currency">Currency</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <select className="form-select"
                                id="type"
                                value={data.type}
                                onChange={e => setData({ ...data, type: e.target.value })}
                                required>
                                <option defaultValue="select type">Select type</option>
                                <option value="Bank">Bank</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque in hand">Cheque in hand</option>
                                <option value="Cheque outwards">Cheque outwards</option>
                            </select>
                            <label htmlFor="type">Type</label>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-floating">
                            <input type='text'
                                className='form-control'
                                id='value'
                                value={data.value}
                                onChange={e => setData({ ...data, value: e.target.value })}
                                required
                                disabled={isEdit}
                            />
                            <label htmlFor="value">Value</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className="col-md">
                        <div className="form-floating">
                            <textarea
                                type='text'
                                className='form-control'
                                id='desc'
                                value={data.desc}
                                onChange={e => setData({ ...data, desc: e.target.value })}
                                required
                            />
                            <label htmlFor="desc">Description</label>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 justify-content-center'>
                    <div className='col-3'>
                        <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateCoa;