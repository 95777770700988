import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FiMoreVertical, FiPlus } from 'react-icons/fi';
import { FaFilePdf } from 'react-icons/fa';
import { numberToWords } from 'number-to-words';
import moment from "moment";
import swal from 'sweetalert';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { charOfAccount, transactions } from "../../config/api-routes";

const header = ['Date', 'Particulars', 'Bank', 'Received', 'Paid', 'LOAN', 'Balance'];

function TransactionList() {
    const param = useParams();
    const auth = useSelector(state => state.auth);
    const [deal, setDeal] = useState({
        title: '',
        contact: {
            name: '',
        },
        createdAt: moment().format('MMM-DD-YYYY'),
        value: 0,
        desc: '',
        type: '',
        coa: {
            title: '',
        },
    });
    const [transactionsList, setTransactionsList] = useState([]);
    const [outwardTransactions, setOutwardTransactions] = useState([]);
    const [selectedTransactions, setSelectedTransactions] = useState([]);   
    const [totalAmount, setTotalAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isOutwards, setIsOutwards] = useState(false);
    const [clearTransaction, setClearTransaction] = useState({
        id:'',
        date:'',
        coa:'',
    });
    const [banks, setBanks] = useState([]);

    const fetchAccounts = useCallback(async () => {
        try {
            const { data } = await axios.get(charOfAccount);
            const filteredBanks = data.accounts.filter(bank => bank.type !== 'Cheque outwards');
            setBanks(filteredBanks);
        } catch(error){
            swal('Failed!', error.response.data, 'error');
        }
    }, []);

    const fetchTransaction = useCallback(async () => {
        try {
            const { data } = await axios.get(`${transactions}/deal/${param.id}`);
            setDeal({
                title: data.deal.title,
                contact: {
                    name: data.deal.contact.name,
                },
                createdAt: moment(data.deal.createdAt).format('MMM-DD-YYYY'),
                value: data.deal.value,
                desc: data.deal.desc,
                type: data.deal.type,
                coa: data.coa,
            });
            const transacationsData = [];
            const outwardData =[];            
            let balanceAmount = data.deal.value;
            let amountInTotal = 0;
            let balanceOutwards = data.deal.value;            
            // calculations for the deal
            data.transactions.forEach((each) => {
                balanceAmount = balanceAmount - each.amount;
                transacationsData.push({
                    ...each,
                    balance: balanceAmount,
                });
                amountInTotal += each.amount;
            });
            data.outwardTransactions.forEach((each) => {
                balanceOutwards = balanceOutwards - each.amount;
                outwardData.push({
                    ...each,
                    balance: balanceOutwards,
                });
            });
            setTransactionsList(transacationsData);
            setOutwardTransactions(outwardData);
            setTotalAmount(amountInTotal);
            setBalance(data.deal.value - amountInTotal);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        fetchTransaction();
        fetchAccounts();
    }, [fetchTransaction, fetchAccounts]);

    const handleDelete = id => () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                try {
                    const response = await axios.delete(`${transactions}/${id}`);                    
                    if (response) { 
                        swal(response.data.success, 'success').then(fetchTransaction());
                    }
                } catch (response) {
                    swal(response.data, 'error');
                }
            } else {
                swal("This record is safe!");
            }
        });
    }

    const handleSubmit = async () => {
        try {
            const response = await axios.put(`${transactions}/${clearTransaction.id}`,clearTransaction);
            swal('Good Job!', response.data.success, 'success').then(fetchTransaction());
        } catch (error) {
            swal(error,'error');
        }
    }

    const handleTransactionSelect = (transactionId) => {
        if (selectedTransactions.includes(transactionId)) {
            setSelectedTransactions(selectedTransactions.filter((id) => id !== transactionId));
        } else {
            setSelectedTransactions([...selectedTransactions, transactionId]);
        }
    };

    const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const marginRight = 450;
        const footerMargin = 50;
        const doc = new jsPDF(orientation, unit, size);
        const title = deal.title;
        const date = moment().format('MMM-DD-YYYY');
        const dealBy = auth.user.name;
        let posY = 155;
        
        doc.setFillColor("#008080")
        doc.rect(0,100,400,10,'F')
        doc.setFontSize(40);
        doc.text('INVOICE',415,110)

        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('Invoice to:    ',marginLeft,posY); 
        doc.setFont('helvetica', 'normal');

        doc.setFontSize(15);                               
        doc.text('Deal:',marginLeft,posY+=40);        
        doc.text('Invoice No.:',marginLeft,posY+=20);
        doc.text('Date:',marginLeft+340,posY);

        doc.setTextColor(64, 64, 64);
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text(deal.contact.name,marginLeft+100,155);
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(15);
        doc.text(title,marginLeft+100,195);                
        doc.text('_______________',marginLeft+100,215);
        doc.text(moment(date).format('MMM-DD-YYYY'),marginLeft+400,215);
        doc.setTextColor(0, 0, 0);
        
        const selectedTransactionData = (isOutwards ? outwardTransactions : transactionsList)
            .filter((each) => selectedTransactions.includes(each._id));
    
        // Table content
        const tableData = selectedTransactionData.map((transaction, idx) => ({
            idx: idx + 1,
            particulars: transaction.particulars,
            type: deal.type === 'sell' ? 'received' : deal.type === 'purchase' ? 'paid' : 'LOAN',
            instrument: transaction.instrument ? transaction.instrument : '-',
            amount: transaction.amount,
            chequeNO: transaction.chequeNo ? transaction.chequeNo : '-',
        }));
    
        const tableStyles = {
            fontStyle: 'bold',
            fontSize: 13,
        };
        doc.autoTable({
            body: tableData,
            startY: 250, 
            startX: marginLeft,
            styles: tableStyles, 
            headerStyles: {fillColor : '#008080'},
            columns: [
                { header: '#', dataKey: 'idx' },
                { header: 'Particulars', dataKey: 'particulars' },
                { header: 'Type', dataKey: 'type' },
                { header: 'Instrument', dataKey: 'instrument' },
                { header: 'Cheque No.', dataKey: 'chequeNo' },
                { header: 'Amount', dataKey: 'amount' },
            ],
        });
        const sum = selectedTransactionData.reduce((acc, transaction) => acc + transaction.amount, 0);
        const YCoordinate = 20 + doc.autoTable.previous.finalY;
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(13);
        doc.text('Total: ',marginLeft+10,YCoordinate)
        doc.text('Rs. '+ sum, doc.internal.pageSize.getWidth()-55 - (doc.getStringUnitWidth('Rs. '+ sum) * doc.internal.getFontSize() / doc.internal.scaleFactor),YCoordinate);
        doc.text('[Rs. ' + numberToWords.toWords(sum).toUpperCase() + ' only]', doc.internal.pageSize.getWidth()-80 - (doc.getStringUnitWidth('Rs. ' + numberToWords.toWords(sum) + ' only') * doc.internal.getFontSize() / doc.internal.scaleFactor), YCoordinate+20);
        doc.setFont('helvetica', 'normal'); 

       //footer portion    
        const y=doc.internal.pageSize.height - footerMargin;

        doc.setFontSize(11);
        doc.line(marginLeft-7,y, marginLeft+dealBy.length*8,y,'S');
        doc.text('Accountant', marginLeft, y + 15);

        doc.line(doc.internal.pageSize.width/2-40,y,doc.internal.pageSize.width/2-20+title.length*7,y,'S');
        doc.text(dealBy, doc.internal.pageSize.width/2-20, y + 15);        
        
        doc.line(doc.internal.pageSize.getWidth()-115,y,doc.internal.pageSize.getWidth()-35,y,'S');
        doc.text("Paid to", doc.internal.pageSize.getWidth()-95, y + 15);
        
        doc.save(`${Date.now()}-${deal.title}.pdf`);
    };

    return (
        <div className='users container w-75 m-auto mt-5'>
            <div className="row mb-3">
                <div className="col-6">
                    <h3>{deal.contact.name}</h3>
                </div>
                <div className="col-6 text-end">
                    <h4>As of {moment().format('MMM-DD-YYYY')}</h4>
                </div>
            </div>
            <div className='row mb-2 bg-cgreen text-white p-1 pt-2'>
                <div className='col-5'>
                    <h5 className="text-uppercase">{deal.title}</h5>
                </div>
                <div className="col-7">
                    <div className="row m-auto">
                        <div className="col-2">
                            <h5>Total</h5>
                        </div>
                        <div className="col-2 offset-1">
                            <h5>{totalAmount.toLocaleString("en-US")}</h5>
                        </div>
                        <div className="col-2 offset-1">
                            <h5>{balance.toLocaleString("en-US")}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" row">                
                <div className="form-check col-6 mt-4 mb-2 ps-5">
                    <input 
                        className="form-check-input" 
                        type="checkbox"                         
                        id="flexCheckChecked" 
                        checked= {isOutwards} 
                        onChange={(e) => setIsOutwards(!isOutwards)} 
                        />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Outwards cheque
                    </label>
                </div>                
                <div className=" col-6 text-end mt-4 mb-2">
                    <span className="text-main c-cursor me-3" onClick={exportPDF}>
                        <FaFilePdf className="pdf-icon" />
                    </span>
                    <Link
                        to={`/transaction/new/${param.id}`}
                        className='btn btn-primary btn-wh'
                    >
                        <FiPlus />
                    </Link>
                </div>
            </div>
           
            <div className='table-responsive'>
                <table className="table" id="transaction-table">
                    <thead className='table-primary-head'>
                        <tr>
                            <td/>
                            <td>#</td>
                            {header.map((each, idx) => (
                                <td key={idx}>{each}</td>
                            ))}
                            <td />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td />
                            <td>1</td>
                            <td>{moment(deal.createdAt).format('MMM-DD-YYYY')}</td>
                            <td>Opening Balance</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>{deal.value.toLocaleString("en-US")}</td>
                            <td />
                        </tr>
                        { (isOutwards ? outwardTransactions : transactionsList).map((each, idx) => (
                            <tr key={each._id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedTransactions.includes(each._id)}
                                    onChange={() => handleTransactionSelect(each._id)}
                                />
                            </td>
                                <td>{idx + 2}</td>
                                <td>{moment(each.date).format('MMM-DD-YYYY')}</td>
                                <td>{each.particulars}</td>
                                <td>{each.coa.title ? each.coa.title : ''}</td>
                                <td>{((deal.type === 'sell' && each.amount >= 0) || (deal.type === 'purchase' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : ''}</td>
                                <td>{((deal.type === 'purchase' && each.amount >= 0) || (deal.type === 'sell' && each.amount < 0)) ? Math.abs(each.amount).toLocaleString("en-US") : ''}</td>
                                <td>{deal.type === 'LOAN' ? each.amount.toLocaleString("en-US") : ''}</td>
                                <td>
                                    {each.balance.toLocaleString("en-US")}
                                    {((deal.type === 'sell' && each.amount >= 0) || (deal.type === 'purchase' && each.amount < 0)) ? ' (to be received)' : ' (to be paid)'}
                                </td>
                                <td className='text-end'>
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FiMoreVertical />
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li><Link to={`/transaction/${each._id}`} className="dropdown-item">Edit</Link></li>
                                            <li><Link to={`/transaction/view/${each._id}`} className="dropdown-item">View</Link></li>
                                            <li><button className="dropdown-item" onClick={handleDelete(each._id)}>Delete</button></li>
                                            {isOutwards && <li><button className='dropdown-item' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>setClearTransaction({ ...clearTransaction, id: each._id })}>Clear Cheque</button></li>}                                            
                                        </ul>
                                    </div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Select Chart of Account</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <div className="row  text-center mb-3">
                            <div className="form-floating col-md-6">
                                <input
                                    type='date'
                                    className='form-control'
                                    id='date'
                                    value={moment(clearTransaction.date).format('YYYY-MM-DD')}
                                    onChange={e => setClearTransaction({ ...clearTransaction, date: e.target.value })}
                                    required
                                />
                                <label htmlFor="date" className="ms-2">Clearance Date</label>
                            </div>
                            <div className="form-floating col-md-6">
                                <select 
                                    className="form-select"
                                    id='coa'
                                    value={clearTransaction.coa}
                                    onChange={e => setClearTransaction({ ...clearTransaction, coa: e.target.value })}
                                    required>
                                    <option>Select bank</option>
                                    {banks.map((each) => <option key={each._id} value={each._id}>{each.title}</option>)}   
                                                    
                                </select>
                                <label htmlFor="coa" className="ms-2">Chart of Account</label>
                            </div>
                        </div>
                            <div className="text-end ">
                                <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionList;