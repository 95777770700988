import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { contacts } from "../../config/api-routes";


function CreateContact() {
    const [data, setData] = useState({
        name: "",
        email: "",
        contact: "",
        address: "",
        type: "",
        identity: "",
        business: "",
        contactPerson: "",
    })
    const [isEdit, setIsEdit] = useState(false);
    const param = useParams();
    const history = useHistory();
    const [isCompany, setIsCompany] =  useState(false);

    const fetchContacts = useCallback(async () => {
        try {
            const { data } = await axios.get(`${contacts}/${param.id}`);
            setData(data);
        } catch(error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        if (param.id) {
            setIsEdit(true);
            fetchContacts();
        }
    }, [fetchContacts, param.id]);

    useEffect(() => {
        if(data.type === 'company') {
            setIsCompany(true);
        }
        else{
            setIsCompany(false);
        }
    }, [data.type])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if(isEdit)
            {
                const response = await axios.put(`${contacts}/${param.id}`, data);
                if(response) {
                    swal('Good Job!', response.data.success, 'success')
                    .then(() => history.push(`/contact`));
                }
                return;
            }
            const response = await axios.post(contacts, data);
            if(response) {
                swal('Good Job!', response.data.success, 'success')
                .then(() => history.push(`/contact`));
            }
        } catch (error) {
            swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
        }
    }

    return (

        <div className='container w-50 bg-white p-3 rounded m-auto my-5'>
            <h2>{isEdit ? 'Edit Contact' : 'Create a new Contact'}</h2>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input 
                            type='text'
                            className='form-control'
                            id='name'
                            value={data.name}
                            onChange={e => setData({ ...data, name: e.target.value })}
                            required />
                        <label htmlFor="name">Full Name</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <select 
                            className="form-select"
                            id='type'
                            value={data.type}
                            onChange={e => setData({ ...data, type: e.target.value })}
                            required>
                            <option></option>
                            <option value="individual">Individual</option>
                            <option value="company">Company</option>
                        </select>
                        <label htmlFor="type">Business Type</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="email" className="form-control" id="email" value={data.email}
                            onChange={e => setData({ ...data, email: e.target.value })}
                            />
                        <label htmlFor="email">Email Address</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='contact'
                            value={data.contact}
                            onChange={e => setData({ ...data, contact: e.target.value })}
                            />
                        <label htmlFor="floatingInputGrid">Phone Number</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='identity'
                            value={data.identity}
                            onChange={e => setData({ ...data, identity: e.target.value })}
                            />
                        <label htmlFor="floatingInputGrid">NTN/CNIC</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='busniess'
                            value={data.business}
                            onChange={e => setData({ ...data, business: e.target.value })}
                            />
                        <label htmlFor="floatingInputGrid">Business Name</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='address'
                            value={data.address}
                            onChange={e => setData({ ...data, address: e.target.value })}
                            />
                        <label htmlFor="floatingInputGrid">Complete Business Address</label>
                    </div>
                </div>
                {isCompany ? <div className="col-md">
                    <div className="form-floating">
                        <input type='text'
                            className='form-control'
                            id='contactPerson'
                            value={data.contactPerson}
                            onChange={e => setData({ ...data, contactPerson: e.target.value })}
                            />
                        <label htmlFor="floatingInputGrid">Contact Person</label>
                    </div>
                </div> : ''}
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className='col-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}
export default CreateContact;