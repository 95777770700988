import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import axios from "axios";
import swal from 'sweetalert';
import Pagination from "../pagination";
import { deals } from "../../config/api-routes";

const header = ['Title', 'Type', 'Value', 'Currency', 'Detail'];

export default function DealList() {
    const [data, setData] = useState([]);
    const [dealer, setDealer] = useState({});
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [text, setText] = useState('');
    const [type, setType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const param = useParams();
    
    const fetchContactDeals = useCallback(async () => {
        try {
            const { data } = await axios
                .get(`${deals}/contacts/${param.id}?page=${pagination.page}&search=${text}&type=${type}`);
            setData(data.deals);
            setDealer(data.contact);
            setPagination({
                total: data.total,
                page: data.page,
            });
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }

    }, [param.id, pagination.page, text, type]);

    useEffect(() => {
        fetchContactDeals();
    }, [fetchContactDeals]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);

    const handleDelete = id => () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                try {
                    const response = await axios.delete(`${deals}/${id}`)
                    if (response) {
                        swal(response.data.success, 'success').then(fetchContactDeals());
                    }
                } catch (response) {
                    swal(response.data.error, 'error')
                }
            } else {
                swal("This record is safe!");
            }
        });
    }

    return (
        <div className='users container w-75 m-auto mt-5'>
            <div className='row mb-3'>
                <div className='col-6'>
                    <h2>Deals</h2>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-4'>
                    <h2>{dealer.name}</h2>
                </div>
                <div className='col-4 text-end'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Search by Title'
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                <div className='col-3'>
                    <div className='row align-items-center'>
                        <div className='col-5 text-end'>Filter By:</div>
                        <div className='col-7'>
                            <select className='form-select' onChange={e => setType(e.target.value)}>
                                <option value=''>Type</option>
                                <option value='purchase'>Payable</option>
                                <option value='sell'>Receivable</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-1 text-end'>
                    <Link
                        to={'/deal/new/' + param.id}
                        className='btn btn-primary btn-wh'
                    >
                        <FiPlus />
                    </Link>
                </div>
            </div>
            {data.length > 0 ? (
                <div className='table-responsive'>
                    <table className="table">
                        <thead className='table-primary-head'>
                            <tr>
                                <td>#</td>
                                {header.map((each, idx) => (
                                    <td key={idx}>{each}</td>
                                ))}
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords.map((each, idx) => (
                                <tr key={each._id}>
                                    <td>{idx + 1}</td>
                                    <td>{each.title}</td>
                                    <td className="text-capitalize">{each.type}</td>
                                    <td>{each.value}</td>
                                    <td>{each.currency}</td>
                                    <td>{each.desc}</td>
                                    <td className='text-end'>
                                        <div className="dropdown">
                                            <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FiMoreVertical />
                                            </span>
                                            <ul className="dropdown-menu">
                                                <li><Link to={'/transaction/deals/' + each._id} className="dropdown-item">View Transactions</Link></li>
                                                <li><Link to={'/deal/' + each._id} className="dropdown-item">Edit</Link></li>
                                                <li><button className="dropdown-item" onClick={handleDelete(each._id)}>Delete</button></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : 'No deals found with the given parameters'}
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageNumbers = {disPlayPages}
            />
        </div>
    )
}