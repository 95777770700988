import { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { contacts, dashboard, deals, transactions } from '../../config/api-routes';
import { FiPlus } from 'react-icons/fi';

function DashboardPage() {
    const [analytics, setAnalytics] = useState({
        users: 0,
        chartOfAccounts: {
            sum: [],
            total: 0,
        },
        contacts: 0,
        deals: {
            sell : {
                total: 0,
                sum: []
            },
            purchase : {
                total: 0,
                sum: []
            },
            LOAN : {
                total: 0,
                sum: []
            }
        },
        transactions: {
            credit: {
                count: 0,
                total: 0,
            },
            debit: {
                count: 0,
                total: 0,
            },
            LOAN: {
                count: 0,
                total: 0,
            }
        },
    });
    const [contactsData, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState('');
    const [dealsData, setDeals] = useState([]);
    const [selectedDeal, setSelectedDeal] = useState('');
    const [transactionsData, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const history = useHistory();

    const fetchAnalytics = useCallback(async () => {
        try {
            const { data } = await axios.get(dashboard);
            setAnalytics({
                users: data.users,
                chartOfAccounts: {
                    sum: data.chartOfAccounts.sum,
                    total: data.chartOfAccounts.total,
                },
                contacts: data.contacts,
                deals: {
                    sell : {
                        sum: data.deals.sell.sum ? data.deals.sell.sum : 0,
                        total: data.deals.sell.total,
                    },
                    purchase : {
                        sum: data.deals.purchase.sum ? data.deals.purchase.sum : 0,
                        total: data.deals.purchase.total,
                    },
                    LOAN : {
                        sum: data.deals.LOAN.sum ? data.deals.LOAN.sum : 0,
                        total: data.deals.LOAN.total,
                    }
                },
                transactions: {
                    credit: data.transactions.credit ? {
                        count: data.transactions.credit.count,
                        total: data.transactions.credit.total,
                    } : { count: 0, total: 0} ,
                    debit: data.transactions.debit ? {
                        count: data.transactions.debit.count,
                        total: data.transactions.debit.total,
                    } : { count: 0, total: 0},
                    LOAN: data.transactions.LOAN ? {
                        count: data.transactions.LOAN.sum,
                        total: data.transactions.LOAN.total,
                    } : { count: 0, total: 0},
                }
            });
        } catch (error) {
            swal('Failed!', error.response, 'error');
        }
    }, []);

    const fetchContacts = useCallback(async () => {
        try {
            const { data } = await axios.get(contacts);
            setContacts(data.contacts);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, []);

    useEffect(() => {
        fetchAnalytics();
        fetchContacts();
    }, [fetchAnalytics, fetchContacts]);

    const handleContacts = async (e) => {
        const value = e.target.value;
        setSelectedContact(value);
        try {
            const { data } = await axios.get(`${deals}/contacts/${value}`);
            setDeals(data.deals);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    };

    const handleDeals = async (e) => {
        const value = e.target.value;
        setSelectedDeal(value);
        try {
            const { data } = await axios.get(`${transactions}/deal/${value}`);
            setTransactions(data.transactions);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    };

    return (
        <div className="container m-auto mt-3">
            <h1>Dashboard</h1>
            <div className='row mt-3 ms-md-5'>
                <div className='col-sm-12 col-md-2 bg-white rounded px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Users</h5>
                    <p className='fs-6'>Total: {analytics.users}</p>
                    <hr />
                    <h5 className='mt-1 fw-bold'>Contacts</h5>
                    <p className='fs-5'>Total: {analytics.contacts}</p>
                </div>                
                <div className='col-sm-12 col-md-3 bg-white rounded px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Sell Deals</h5>
                    <p className='fs-5'>Total: {analytics.deals.sell.total}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.deals.sell.sum.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-3 bg-white rounded px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Purchase Deals</h5>
                    <p className='fs-5'>Total: {analytics.deals.purchase.total}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.deals.purchase.sum.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-3 bg-white rounded px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>LOAN Deals</h5>
                    <p className='fs-5'>Total: {analytics.deals.LOAN.total}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.deals.LOAN.sum.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-3 bg-white rounded mt-2 px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Chart Of Accounts</h5>
                    <p className='fs-5'>Total: {analytics.chartOfAccounts.total}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.chartOfAccounts.sum.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-3 bg-white rounded mt-2 px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Credited Transactions</h5>
                    <p className='fs-5'>Total: {analytics.transactions.credit.count}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.transactions.credit.total.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-3 bg-white rounded mt-2 px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>Debited Transactions</h5>
                    <p className='fs-5'>Total: {analytics.transactions.debit.count}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.transactions.debit.total.toLocaleString('en-US')}</p>
                </div>
                <div className='col-sm-12 col-md-2 bg-white rounded mt-2 px-4 py-1 elevate'>
                    <h5 className='mt-1 fw-bold'>LOAN Transactions</h5>
                    <p className='fs-5'>Total: {analytics.transactions.LOAN.count}</p>
                    <hr />
                    <p className='fs-5'>Sum: {analytics.transactions.LOAN.total.toLocaleString('en-US')}</p>
                </div>
            </div>

            <div className='row mt-4'>
                <h2>Transactions</h2>
                <div className='col-sm-12 col-md-3'>
                    <select className="form-select" onChange={handleContacts} value={selectedContact}>
                        <option>Select Contact</option>
                        {contactsData.length 
                            && contactsData.map((each) => <option key={each._id} value={each._id}>{each.name}</option>)}
                    </select>
                    <Link
                        to="/contact/new"                        
                    >
                        <div className='btn text-primary'>Create a new contact</div>
                    </Link>
                </div>
                <div className='col-sm-12 col-md-3'>
                    <select 
                        className="form-select" 
                        disabled={!dealsData.length} 
                        onChange={handleDeals}
                        value={selectedDeal}>
                        <option>Select Deal</option>
                        {dealsData.length 
                            && dealsData.map((each) => <option key={each._id} value={each._id}>{each.title}</option>)}
                    </select>
                    {selectedContact ? <Link
                        to={"/deal/new/" + selectedContact}                       
                    >
                        <div className='btn text-primary'>Create a new deal</div>
                    </Link> : ''}
                </div>
                { isEdit ? 
                    <> <div className='col-sm-12 col-md-3'>
                        <select className="form-select" disabled={!transactionsData.length} onChange={(e) => setSelectedTransaction(e.target.value)}>
                            <option>Select Transaction</option>
                            {transactionsData.length 
                                && transactionsData.map((each) => <option key={each._id} value={each._id}>{each.particulars}</option>)}
                        </select>
                    </div> 
                    <div className='col-sm-12 col-md-1'>
                        <button 
                            className='btn btn-primary w-100 ' 
                            disabled={!selectedTransaction.length} 
                            onClick={() => history.push(`/transaction/${selectedTransaction}`)}>
                            Edit
                        </button>                        
                    </div> 
                    <div className='col-sm-12 col-md-2'>
                            <button className='btn text-primary' onClick={() => setIsEdit(false)}>Add a new entry</button>
                        </div></> 
                    : 
                    <><div className='col-sm-12 col-md-2'>
                        <button 
                            className='btn btn-primary w-100'
                            disabled={!selectedDeal.length} 
                            onClick={() => history.push(`/transaction/new/${selectedDeal}`)}>
                            Add
                        </button>                        
                    </div>
                    <div className='col-sm-12 col-md-3 text-primary btn' onClick={() => setIsEdit(true)}> Edit an existing transaction?</div></>
                }                               
            </div>
        </div>
    );
}

export default DashboardPage;