const host = process.env.NODE_ENV === "production" 
    ? "https://api.finomanagement.tech/api" : "http://localhost:3001/api";

export const login = `${host}/login`;
export const register = `${host}/register`;
export const users = `${host}/users`;
export const deals = `${host}/deals`;
export const charOfAccount = `${host}/coa`;
export const transactions = `${host}/transactions`;
export const contacts = `${host}/contacts`;
export const dashboard = `${host}/dashboard`;
export const forgotPassword = `${host}/forgotPassword`;
export const changePassword = `${host}/changePassword`;
