import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import Pagination from "../pagination";
import { charOfAccount } from "../../config/api-routes";
import moment from "moment";
import jsPDF from "jspdf";
import { FaFilePdf } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

const header = ['Date', 'Operation', 'Cheque No', 'Type', 'Amount', 'Balance', 'User'];

function Logs() {
    const param = useParams();
    const [logs,setLogs] = useState([]);
    const [title,setTitle] = useState('');
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [previousData, setPrevioudData] = useState({});

    const fetchCoa = useCallback(async () => {
        try {
            const { data } = await axios.get(`${charOfAccount}/${param.id}`);
            setLogs(data.history.reverse());
            setTitle(data.title);
        } catch (error) {
            swal('Failed!', error.response, 'error');
        }
    }, [param.id]);

    useEffect(() => {
        if (param.id) {
            fetchCoa(); 
        }
    }, [fetchCoa, param.id]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = logs.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(logs.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const disPlayPages = currentPage === 1 ? pageNumbers.slice(currentPage-1,currentPage+2):pageNumbers.slice(currentPage-2,currentPage+2);

    const exportPDF = () => {
        //declaration
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const head = 'Logs - '+title;

        //top portion
        doc.setFontSize(15);
        doc.text(head, marginLeft, 40);

        //table content
        doc.autoTable({
            startY: 80,
            columns:
                [
                    { header: 'No.', dataKey: 'idx' },
                    { header: 'Date', dataKey: 'date' },
                    { header: 'Operation', dataKey: 'operation' },
                    { header: 'Cheque No', dataKey: 'chequeNo' },
                    { header: 'Type', dataKey: 'typeOfTransaction' },
                    { header: 'Value', dataKey: 'value' },
                    { header: 'Account Balance', dataKey: 'accountBalance' },
                    { header: 'Edited By', dataKey: 'editedBy' },
                    

                ],
                body: logs.map((log, idx) => ({
                    idx: (idx + 1).toString(),
                    date: moment(parseInt(log.date, 10)).format('MMM-DD-YYYY'),
                    operation: log.operation.name.length > 25 ? log.operation.name.slice(0, 30) + "..." : log.operation.name,
                    chequeNo: log.chequeNo || '',
                    typeOfTransaction: log.typeOfTransaction === 'sell' ? 'Received' : log.typeOfTransaction === 'purchase' ? 'Paid' : '',
                    value: log.value,
                    accountBalance: log.accountBalance,
                    editedBy: log.editedBy ? log.editedBy.name : '',
                })),
            });        
        
        doc.save('COA-Logs.pdf');
    };

  return (
    <div className="users container w-75 m-auto mt-5">
        <div className="row">
            <div className=' mb-3 col-10'>
                <div className='col-6'>
                    <h2>Logs - {title}</h2>
                </div>            
            </div>
            <div className='mb-3 col-2'>
                <div className='col-12 text-end'>
                <span className="text-main c-cursor me-3" onClick={exportPDF}>
                    <FaFilePdf className="pdf-icon" />
                </span>
                </div>
            </div>
        </div>
        <div className="table-responsive">
            <table className="table" id="logs-table">
                <thead className='table-primary-head'>
                    <tr>
                        <td>#</td>
                        {header.map((each, idx) => (
                            <td key={idx}>{each}</td>
                        ))}                        
                    </tr>
                </thead>
                <tbody>
                    {currentRecords.map((each,idx) => (
                        <tr key={idx}>
                            <td>{idx+1}</td>
                            <td>{moment(parseInt(each.date,10)).format('MMM-DD-YYYY')}</td>
                            <td title={each.operation.name}>{each.operation.name.length > 25 ? each.operation.name.slice(0,30) + "..." : each.operation.name}</td>
                            <td>{each.chequeNo ? each.chequeNo : ''}</td>
                            <td>{each.typeOfTransaction ? each.typeOfTransaction === 'sell' ? 'Received' : 'Paid' : ''}</td>
                            <td>{each.value}</td>
                            <td>{each.accountBalance}</td>
                            <td>{each.editedBy ? each.editedBy.name : ''}</td>
                            <td className='text-end'>
                                { each.previousData ? <div className="dropdown">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FiMoreVertical />
                                    </span>
                                    <ul className="dropdown-menu">
                                        <li><button className="dropdown-item" onClick={()=>setPrevioudData(each.previousData)} data-bs-toggle="modal" data-bs-target="#staticBackdrop">View Previous Data</button></li>                                            
                                    </ul>
                                </div> : ''}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Transaction data before update</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Date: {moment(parseInt(previousData.date,10)).format('MMM-DD-YYYY') } <br/>
                        Amount: {previousData.amount } <br/>
                        Particulars: {previousData.particulars} <br/>
                        COA: {previousData.coa } <br/>
                        Currency:  {previousData.currency }   <br/>               
                        Instrument:{previousData.instrument } <br/>
                        Paid by: {previousData.paidBy } <br/>
                        Cheque No.:{previousData.chequeNo } <br/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers = {disPlayPages}
        />
    </div>
  )
}

export default Logs