import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import moment from 'moment';
import { transactions, charOfAccount } from "../../config/api-routes";

function CreateTransaction() {
    const [banks, setBanks] = useState([])
    const [selectedBankType,setBankType] = useState('');
    const [isEdit, setIsedit] = useState(false)
    const [data, setData] = useState({
        date: "",
        isCredit: true,
        amount: "",
        currency: "PKR",
        particulars: "",
        coa: "",
        chequeNo: "",
        typeOfCheque: '',
        paidBy: '',
        instrument: '',
    });

    const param = useParams();
    const history = useHistory();

    const fetchAccounts = useCallback(async () => {
        try {
            const { data } = await axios.get(charOfAccount);
            setBanks(data.accounts);
        } catch(error){
            swal('Failed!', error.response.data, 'error');
        }
    }, []);

    const fetchTransaction = useCallback(async () => {
        try {
            const { data } = await axios.get(`${transactions}/${param.id}`);
            setData(data);
        } catch (error) {
            swal('Failed!', error.response.data, 'error');
        }
    }, [param.id])

    useEffect(() => {
        if (param.id) {
            setIsedit(true);
            fetchTransaction();
        }
        fetchAccounts();
    }, [fetchTransaction, fetchAccounts, param.id]);

    useEffect( () => {
        const findBank = banks.find( bank => bank._id === data.coa);
        if (findBank) setBankType(findBank.type);
    },[data.coa])

    const handleSubmit = async (e) => { 
        e.preventDefault();
        try {
            if (isEdit) {
                const response = await axios.put(`${transactions}/${param.id}`, data);
                if (response) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.goBack());
                }
            } else {
                const response = await axios.post(`${transactions}/deal/${param.dealId}`, data);
                if (response) {
                    swal('Good Job!', response.data.success, 'success')
                        .then(() => history.push(`/transaction/view/${response.data.saveTransaction._id}`));
                }
            }
        } catch (error) {
            swal('Failed!', Object.values(error.response.data).join('\n'), 'error');
        }
    }

    return (
        <div className='container w-50 bg-white p-3 rounded m-auto my-5'>
            <h2>{isEdit ? 'Edit Transaction' : 'Create Transaction'}</h2>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input
                            type='date'
                            className='form-control'
                            id='date'
                            value={isEdit ? moment(data.date).format('YYYY-MM-DD') : data.date}
                            onChange={e => setData({ ...data, date: e.target.value })}
                            required
                        />
                        <label htmlFor="date">date</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <select 
                            className="form-select"
                            id='coa'
                            value={ data.coa ? data.coa.title : ''}
                            onChange={e => setData({ ...data, coa: e.target.value })}
                            disabled = {isEdit}
                            required>
                            <option>{isEdit ? data.coa ? data.coa.title : '' :'Select bank'}</option>
                            {banks.map((each) => <option key={each._id} value={each._id}>{each.title}</option>)}   
                        </select>
                        <label htmlFor="coa">Chart of Account</label>
                    </div>
                    {!isEdit && <Link
                        to="/coa/new"                        
                    >
                        <div className="btn text-primary">Create a new account?</div>
                    </Link>  }                  
                </div>
            </div>
            <div className='row mt-3 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <input 
                            type='text'
                            className='form-control'
                            id='amount'
                            value={data.amount}
                            onChange={e => setData({ ...data, amount: e.target.value })}
                            required 
                        />
                        <label htmlFor="amount">Amount</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="col-md">
                    <div className="form-floating">
                        <textarea className='form-control deal'
                            id='particulars'
                            value={data.particulars}
                            onChange={e => setData({ ...data, particulars: e.target.value })}
                            required 
                        />
                        <label htmlFor="particulars">Particulars</label>
                    </div>
                </div>
            </div>            
            <div className="row my-5">
                <p className="lead">Transaction Information</p>
                {selectedBankType==='Cash' ? '':
                 <div className="col-md-6 mt-2">
                    <div className="form-floating">
                        <input 
                            type='text'
                            className='form-control'
                            id='chequeNo'
                            value={data.chequeNo}
                            onChange={e => setData({ ...data, chequeNo: e.target.value })}
                        />
                        <label htmlFor='chequeNo'>Cheque No.</label>
                    </div>
                </div>}
                <div className="col-md-6 mt-2">
                    <div className="form-floating">
                        <select 
                            className="form-select"
                            id='coa'
                            value={data.typeOfCheque}
                            onChange={e => setData({ ...data, typeOfCheque: e.target.value })}
                            >
                            <option />
                            <option>Received</option>
                            <option>Paid</option>
                        </select>
                        <label htmlFor="coa">Type of transaction</label>
                    </div>
                </div>
                <div className="col-md-6 mt-2">
                    <div className="form-floating">
                        <input 
                            type='text'
                            className='form-control'
                            id='paidBy'
                            value={data.paidBy}
                            onChange={e => setData({ ...data, paidBy: e.target.value })}
                        />
                        <label htmlFor="paidBy">Paid By</label>
                    </div>
                </div>
                <div className="col-md-6 mt-2">
                    <div className="form-floating">
                        <input 
                            type='text'
                            className='form-control'
                            id='instrument'
                            value={data.instrument}
                            onChange={e => setData({ ...data, instrument: e.target.value })}
                        />
                        <label htmlFor="instrument">Instrument</label>
                    </div>
                </div>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className='col-3'>
                    <button className='btn btn-primary btn-wh w-100' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default CreateTransaction;