import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';

import LoginPage from './pages/auth/LogIn';
import DashboardPage from './pages/dashboard';
import ContactList from './pages/contact/list';
import CreateContact from './pages/contact/create';
import CoaList from './pages/coa/list';
import CreateCoa from './pages/coa/create';
import CreateDeal from './pages/deal/create';
import DealList from './pages/deal/list';
import CreateTransaction from './pages/transaction/create';
import TransactionList from './pages/transaction/list';
import TransactionView from './pages/transaction/view';
import UserList from './pages/user/list';
import CreateUser from './pages/user/create';
import GeneralLedger from './pages/generalLedger/legder';
import Transactions from './pages/transaction/transactions';
import Summary from './pages/summary/list';
import Logs from './pages/coa/logs';
import ForgotPassword from './pages/auth/forgotPassword';
import Register from './pages/auth/Register';

export const Routes = () => (
    <Switch>
        <Route exact component={LoginPage} path='/' />
        <Route exact component={ForgotPassword} path='/forgotPassword' />
        <Route exact component={Register} path='/register' />
        <PrivateRoute exact component={DashboardPage} path='/dashboard' />
        <PrivateRoute exact component={UserList} path='/user' />
        <PrivateRoute exact component={CreateUser} path='/user/new' />
        <PrivateRoute exact component={CreateUser} path='/user/:id' />
        <PrivateRoute exact component={ContactList} path='/contact' />
        <PrivateRoute exact component={CreateContact} path='/contact/new' />
        <PrivateRoute exact component={CreateContact} path='/contact/:id' />
        <PrivateRoute exact component={CoaList} path='/coa' />
        <PrivateRoute exact component={CreateCoa} path='/coa/new' />
        <PrivateRoute exact component={CreateCoa} path='/coa/:id' />
        <PrivateRoute exact component={Logs} path='/logs/coa/:id'/>
        <PrivateRoute exact component={CreateDeal} path='/deal/new/:contactId' />
        <PrivateRoute exact component={CreateDeal} path='/deal/:id' />
        <PrivateRoute exact component={DealList} path='/deal/view/:id' />
        <PrivateRoute exact component={Transactions} path='/transaction'/>
        <PrivateRoute exact component={Transactions} path='/transaction/coa/:id'/>
        <PrivateRoute exact component={CreateTransaction} path='/transaction/new/:dealId' />
        <PrivateRoute exact component={CreateTransaction} path='/transaction/:id' />
        <PrivateRoute exact component={TransactionList} path='/transaction/deals/:id' />
        <PrivateRoute exact component={TransactionView} path='/transaction/view/:id' />
        <PrivateRoute exact component={Summary} path='/summary'/>
        <PrivateRoute exact component={GeneralLedger} path='/general-ledger' />                        
        <Route path='*'>
            Not Found
        </Route>
    </Switch>
);
